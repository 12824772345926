export const getLastElement = <T>(
  elements: T[] | null | undefined
): T | null => {
  if (!elements) {
    return null;
  }

  const l = elements.length;

  if (l === 0) {
    return null;
  }

  return elements[l - 1];
};

export const getLastElementOrThrow = <T>(
  elements: T[] | null | undefined
): T => {
  const element = getLastElement(elements);

  if (!element) {
    throw new Error('no elements were provided');
  }

  return element;
};
